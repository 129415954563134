<template>
    <el-button
        :icon="icon"
        :size="size"
        :plain="plain"
        :round="round"
        :circle="circle"
        :type="typeStyle"
        :autofocus="false"
        :loading="fLoading"
        :disabled="btn_disabled"
        @click="handleClick(clickEvent)"
    >
        {{ fContent }}
    </el-button>
</template>

<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yes-button",
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        content: {
            type: String
        },
        size: {
            type: String
        },
        typeStyle: {
            type: String
        },
        plain: {
            type: Boolean
        },
        round: {
            type: Boolean
        },
        circle: {
            type: Boolean
        },
        loading: {
            type: Boolean
        },
        disabled: {
            type: Boolean
        },
        icon: {
            type: String
        },
        dataSource: String,
        clickEvent: String,
        afterCreateScript: String,
        // 是否需要阻止重复点击
        preventClick: {
            type: Boolean,
            default: true
        },
        // 阻止重复点击时间[默认2000ms]
        preventTime: {
            type: Number,
            default: 2000
        },
        // 重复点击时, 是否弹出提示
        showPreventTip: {
            type: Boolean,
            default: false
        },
        // 重复点击提示内容
        preventClickTip: {
            type: String,
            default: "执行操作中, 请勿重复点击"
        }
    },
    data() {
        return {
            time: 0,
            fvalue: "",
            fDataSource: this.dataSource,
            btn_disabled: false,
            fLoading: this.loading,
            fContent: this.content
        };
    },
    created() {
        this.btn_disabled = this.disabled;
    },
    async mounted() {
        this.afterCreate();
    },
    methods: {
        setCircle(circle) {
            this.circle = circle;
        },
        setContent(content) {
            this.fContent = content;
        },
        getContent() {
            return this.fContent;
        },
        setDisabled(value) {
            this.btn_disabled = value;
        },
        setLoading(value) {
            this.fLoading = value;
        },
        getValue() {
            return this.fvalue;
        },
        // 修改组件的值
        setValue(val) {
            this.fvalue = val;
        },
        loadSourceData() {
            const req = utils.getSourceData(this.fDataSource);
            if (req) {
                this.fvalue = req;
            }
        },
        isDarg() {
            if (this.$parent.$options._componentTag == "dragTool") {
                return true;
            }
        },
        async handleClick() {
            if (!this.isDarg()) {
                if (this.handlePrevent()) return;
                const clickEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", this.clickEvent);
                await clickEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils);
            }
        },
        async afterCreate() {
            const afterCreateEvent = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", this.afterCreateScript);
            await afterCreateEvent(this, this.formCreateInject.api, dao, this.formCreateInject, utils);
        },
        /**
         * 阻止重复点击
         * T: 阻止 F: 不阻止
         */
        handlePrevent() {
            if (!this.preventClick) return false;
            const nowTime = Date.now();
            const intervalTime = nowTime - this.time;
            // 如果点击间隔小于 this.preventTime 则不执行后续操作
            let result = false;
            if (intervalTime < this.preventTime) {
                // 是否显示 提示信息
                if (this.showPreventTip) {
                    utils.messageWarning(this.preventClickTip);
                }
                result = true;
            }
            // 更新为当前时间戳
            this.time = nowTime;
            return result;
        }
    }
};
</script>

<style></style>