<template>
    <div
        v-if="isDrag"
        style="display: flex; color: #606266"
    >
        <span style="margin-left: 40px">{{ "dataSource：" }}</span>
        <span>{{
      formCreateInject.rule.name ? "(" + formCreateInject.rule.name + ")：" : ""
    }}</span>
        <span>{{ type }}</span>
        <span v-if="type == 'view'"> {{ appCode ? "：" + appCode : "" }}</span>
        <span v-if="type == 'view'"> {{ viewCode ? "：" + viewCode : "" }}</span>
        <span v-if="type == 'model'"> {{ appCode ? "：" + appCode : "" }}</span>
        <span v-if="type == 'model'"> {{ model ? "：" + model : "" }}</span>
        <span v-if="type == 'api'"> {{ appCode ? "：" + appCode : "" }}</span>
        <span v-if="type == 'api'"> {{ apiCode ? "：" + apiCode : "" }}</span>
        <span v-if="type == 'api'"> {{ apiType ? "：" + apiType : "" }}</span>
        <span v-if="type == 'config'"> {{ appCode ? "：" + appCode : "" }}</span>
        <span v-if="type == 'config'">
            {{ configUrl ? "：" + configUrl : "" }}</span>
    </div>
</template>
<script>
const dao = require("@/api/core/dao");
const utils = require("@/api/core/utils");
export default {
    name: "yesDataSource",
    props: {
        // sourceName:String,
        appCode: String,
        type: String,
        viewCode: {
            type: String,
            defeat: "",
        },
        initSize: String,
        model: {
            type: String,
            defeat: "",
        },
        apiCode: {
            type: String,
            defeat: "",
        },
        apiType: String,
        configUrl: String,
        initData: {
            type: [Object, Array],
            defeat: () => ({}),
        },
        initDataValue: {
            type: [Object, Array],
            defeat: () => ({}),
        },
        firstLoad: {
            type: Boolean,
            defeat: true,
        },
        formCreateInject: {
            type: Object,
            required: true,
        },
        afterLoadEvent: String,
        beforeLoadEvent: String,
        // value: Object,
    },
    data() {
        return {
            total: 0,
            data: {},
            queryData: {},
            pageQuery: {
                page: 1,
                size: 20,
            },
            typeList: {
                view: "视图",
                model: "模型",
                api: "接口",
                config: "配置",
            },
            isDrag: false,
            state: false,
        };
    },
    //   watch: {
    //     value(val) {
    //       console.log("val...", val);
    //       this.data = val;
    //     },
    //   },
    created() {
        this.queryData = this.initData ? this.initData : {};
        this.data = this.initDataValue ? this.initDataValue : this.data;
        this.pageQuery.size = this.initSize != undefined && this.initSize != "" ? Number(this.initSize) : this.pageQuery.size;
        if (this.$parent.$options._componentTag === "dragTool") {
            this.isDrag = true;
        } else {
            this.init();
        }
    },
    methods: {
        getLoadState() {
            return this.state;
        },
        getValue() {
            return this.data;
        },
        getFirstLoad() {
            return this.firstLoad;
        },
        async setValue(value, key) {
            if (key != undefined) {
                this.$set(this.data, key, value);
            } else {
                this.data = value;
            }
            this.afterLoadMethod();
            this.noticeChange();
        },
        // 加载完读取子组件的初值
        init() {
            this.formCreateInject.api
                .all()
                .filter((item) => item.props.dataSource != undefined && item.props.dataSource.split(".")[0] === this.formCreateInject.prop.name)
                .forEach((item) => {
                    if (item.props.dataSource) {
                        this.$nextTick(() => {
                            item.__fc__.el && item.__fc__.el.updateSourceData && item.__fc__.el.updateSourceData();
                        });
                    }
                });
        },
        // 数据变化时，修改子组件的值
        noticeChange() {
            this.formCreateInject.api
                .all()
                .filter((item) => item.props.dataSource != undefined && item.props.dataSource.split(".")[0] === this.formCreateInject.prop.name)
                .forEach((item) => {
                    if (item.props.dataSource) {
                        item.__fc__.el && item.__fc__.el.loadSourceData && item.__fc__.el.loadSourceData();
                    }
                });

            this.formCreateInject.api
                .all()
                .filter((item) => item.props.viewDataSource != undefined && item.props.viewDataSource.split(".")[0] === this.formCreateInject.prop.name)
                .forEach((item) => {
                    if (item.props.viewDataSource) {
                        item.__fc__.el && item.__fc__.el.loadViewSourceData && item.__fc__.el.loadViewSourceData();
                    }
                });
        },
        setQueryData(data, key) {
            if (data != undefined) {
                if (key == undefined) {
                    this.queryData = data;
                } else {
                    this.$set(this.queryData, key, data);
                }
            }
        },
        async beforeLoadMethod() {
            if (this.beforeLoadEvent) {
                const beforeLoad = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", this.beforeLoadEvent);
                await beforeLoad(this, this.formCreateInject.api, dao, this.formCreateInject, utils);
            }
        },
        async afterLoadMethod() {
            if (this.afterLoadEvent) {
                const afterLoad = window.AsyncFunction("vm", "api", "dao", "fApi", "utils", this.afterLoadEvent);
                await afterLoad(this, this.formCreateInject.api, dao, this.formCreateInject, utils);
            }
        },
        async load(page, size) {
            await this.beforeLoadMethod();
            this.state = false;
            if (this.type == "view") {
                await this.loadView(page, size);
            } else if (this.type == "moreModel") {
                await this.loadMoreModel(page, size);
            } else if (this.type == "model") {
                await this.loadModel();
            } else if (this.type == "api") {
                await this.loadApi(page, size);
            } else if (this.type == "app") {
                await this.loadApp(page, size);
            } else if (this.type == "config") {
                await this.loadConfig();
            }
            this.noticeChange();
            await this.afterLoadMethod();
            this.state = true;
        },
        async post(data) {
            if (this.type == "view") {
                this.$message({
                    message: "视图数据源无修改方法",
                    type: "warning",
                });
            } else if (this.type == "model") {
                return await this.modelPost(data);
            } else if (this.type == "api") {
                return await this.apiPost(data);
            }
        },
        async delete() {
            if (this.type == "view") {
                this.$message({
                    message: "视图数据源无删除方法",
                    type: "warning",
                });
            } else if (this.type == "model") {
                return await this.modelDelete();
            } else if (this.type == "api") {
                return await this.apiDelete();
            }
        },
        async loadModel() {
            const res = await dao.modelGetByUid(this.appCode, this.model, this.queryData.uid);
            this.data = res.data;
            if (res.count || res.count === 0) {
                this.total = res.count;
            }
        },
        async loadApi(page, size) {
            if (page != undefined) {
                this.pageQuery.page = page;
                this.queryData.page = page;
            }
            if (size != undefined) {
                this.pageQuery.size = size;
                this.queryData.size = size;
            }
            const res = await dao.modelApiPost(this.appCode, this.model, this.apiCode, this.queryData);
            this.data = res.data;
            if (res.count || res.count === 0) {
                this.total = res.count;
            }
            //   this.$emit("input", this.data);
        },
        async loadApp(page, size) {
            if (page != undefined) {
                this.pageQuery.page = page;
                this.queryData.page = page;
            }
            if (size != undefined) {
                this.pageQuery.size = size;
                this.queryData.size = size;
            }
            const res = await dao.apiPost(this.appCode, this.apiCode, this.queryData);
            this.data = res.data;
            if (res.count || res.count === 0) {
                this.total = res.count;
            }
            this.$emit("input", this.data);
        },
        async loadConfig() {
            const res = await dao.getConfig(this.appCode, this.configUrl);
            this.data = res.data;
        },
        async loadView(page, size) {
            if (page != undefined) {
                this.pageQuery.page = page;
            }
            if (size != undefined) {
                this.pageQuery.size = size;
            }
            const res = await dao.view(this.appCode, this.viewCode, this.queryData, this.pageQuery.page, this.pageQuery.size);
            this.data = res.data;
            this.total = res.count;
            this.$emit("input", this.data);
        },
        async loadMoreModel(page, size) {
            if (page != undefined) {
                this.pageQuery.page = page;
            }
            if (size != undefined) {
                this.pageQuery.size = size;
            }
            const res = await dao.collect(this.appCode, this.viewCode, this.queryData, this.pageQuery.page, this.pageQuery.size);
            this.data = res.data;
            this.total = res.count;
            this.$emit("input", this.data);
        },
        async modelPost(data) {
            if (!data) data = this.data;
            return await dao.modelPost(this.appCode, this.model, data);
        },
        async apiPost(data) {
            return await dao.modelApiPost(this.appCode, this.model, this.appCode, data);
        },
        async modelDelete() {
            return await dao.modelDeleteByUid(this.appCode, this.model, this.data.uid);
        },
        async apiDelete() {
            return await dao.modelApiDelete(this.appCode, this.model, this.appCode, this.data.uid);
        },
        initValue() {
            if (!this.isDrag) {
                if (this.firstLoad) {
                    this.load();
                } else {
                    this.noticeChange();
                }
            }
        },
        getTotal() {
            return this.total;
        },
        getPageQuery() {
            return this.pageQuery;
        },
        setPageQuery(val) {
            this.pageQuery = val;
            // return this.pageQuery;
        },
    },
};
</script>