<template>
    <el-tabs
        :id="tabsId"
        v-model="editableTabsValue"
        :type="type"
        :tabPosition="tabPosition"
        :stretch="stretch"
        :addable="addable"
        @tab-click="clickTab"
        @tab-remove="removeTabConfirm"
        @tab-add="addTab"
        :style="{ height: tabsHeight }"
    >
        <slot />
        <!-- <el-tab-pane label="ssss" name="ffff" >
       <p>ssss</p>
    </el-tab-pane> -->
    </el-tabs>
</template>

<script>
import uniqueId from '@form-create/utils/lib/unique';
const utils = require('@/api/core/utils');
const dao = require('@/api/core/dao');
export default {
    name: 'yes-tabs',
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        children: {
            type: Array
        },
        type: {
            type: String,
            default: 'card'
        },
        // closable: {
        //   type: Boolean,
        //   default: false
        // },
        tabPosition: {
            type: String,
            default: 'top'
        },
        stretch: {
            type: Boolean,
            default: false
        },
        addable: {
            type: Boolean,
            default: false
        },
        tabClickEvent: {
            type: String
        },
        maxLength: {
            type: Number
        },
        width: {
            type: String
        },
        height: {
            type: String
        },
        fontFamily: {
            type: String
        },
        fontFamilyTime: {
            type: Number,
            default: 1000
        },
        tabsHeight: {
            type: String
        }
    },
    data() {
        return {
            tabsId: uniqueId(),
            editableTabsValue: '',
            otherData: {},
            slotLength: 0
        };
    },
    created() {},
    updated() {
        if (this.editableTabsValue === '0' && this.$slots.default) {
            this.editableTabsValue = this.$slots.default[0].componentOptions.propsData.name;
            this.slotLength = this.$slots.default.length;
        }
        if (this.$slots.default && this.$slots.default.length > this.slotLength) {
            this.slotLength = this.$slots.default.length;
            this.editableTabsValue = this.$slots.default
                ? this.$slots.default[this.$slots.default.length - 1].componentOptions.propsData.name
                : this.editableTabsValue;
        }
    },
    methods: {
        addTab() {
            if (this.$parent.$options._componentTag === 'dragTool') {
                this.$parent.$emit('addChild');
                return;
            }
            this.addRule();
        },
        addRule(data = {}, rule = []) {
            //添加子组件addRule
            if (rule || rule.length === 0) {
                return this.$message.warning('对象rule长度为0, 请先添加子组件');
            }
            if (!data.name) {
                return this.$message.warning('对象data字段值不能为空');
            }
            const tabRule = this.formCreateInject.rule;
            var isExit = tabRule.children.find(r => r.props.name === data.name);
            if (isExit) {
                this.editableTabsValue = data.name;
                return false;
            }
            const flag = this.judgeMaxLength(tabRule, '组件');
            if (flag.flag) {
                return this.$message.warning(flag.msg);
            }
            const tabPaneRule = {
                type: 'el-tab-pane',
                props: {
                    label: data.label || '新标签页',
                    name: data.name,
                    closable: true,
                    close_confirm: data.close_confirm
                },
                children: rule,
                _fc_drag_tag: 'yes-tabs-pane',
                hidden: false,
                display: true
            };
            tabRule.children.push(tabPaneRule);
            this.editableTabsValue = data.name;
        },
        addComponent(data = {}) {
            const arr = this.judgeFieldNotNull(data, 'addComponent');
            if (arr.length !== 0) {
                this.$message.warning(`存在字段${arr}值为空,请输入`);
                return false;
            }
            const tabRule = this.formCreateInject.rule;
            var isExit = tabRule.children.find(r => r.props.name === data.name);
            if (isExit) {
                this.editableTabsValue = data.name;
                return false;
            }
            const flag = this.judgeMaxLength(tabRule, '组件');
            if (flag.flag) {
                return this.$message.warning(flag.msg);
            }
            const tabPaneRule = {
                name: data.name,
                type: 'el-tab-pane',
                props: {
                    label: data.label,
                    name: data.name,
                    closable: data.closable
                },
                children: [
                    {
                        name: uniqueId(),
                        type: 'yes-component',
                        props: {
                            app: data.app || '',
                            code: data.code || ''
                        },
                        _fc_drag_tag: 'yes-component',
                        hidden: false,
                        display: true
                    }
                ],
                _fc_drag_tag: 'yes-tabs-pane',
                hidden: false,
                display: true
            };
            tabRule.children.push(tabPaneRule);
            this.editableTabsValue = data.name;
        },
        addIframe(data = {}) {
            const arr = this.judgeFieldNotNull(data);
            if (arr.length !== 0) {
                this.$message.warning(`存在字段${arr}值为空,请输入`);
                return false;
            }
            const tabRule = this.formCreateInject.rule;
            // var url = `${window.location.origin}${window.location.pathname}#/preview?app=${data.app}&code=${data.code}`;
            var url = data.url;
            var isExitItem = tabRule.children.find(r => r.props.name === data.name);
            if (isExitItem) {
                if (isExitItem.children.length > 0) {
                    this.editableTabsValue = data.name;
                    return false;
                } else {
                    tabRule.children.some((item, i) => {
                        if (item.name === isExitItem.name) {
                            tabRule.children.splice(i, 1);
                        }
                    });
                }
            }
            const flag = this.judgeMaxLength(tabRule, '窗口');
            if (flag.flag) {
                return this.$message.warning(flag.msg);
            }
            const tabPaneRule = {
                type: 'el-tab-pane',
                props: {
                    label: data.label || '新标签页',
                    name: data.name,
                    closable: data.closable,
                    close_confirm: data.close_confirm
                },
                children: [
                    {
                        type: 'yes-iframe',
                        props: {
                            url: url,
                            width: this.width,
                            height: this.height,
                            iframeId: data.name,
                            fontFamily: this.fontFamily,
                            fontFamilyTime: this.fontFamilyTime
                        },
                        _fc_drag_tag: 'yes-iframe',
                        hidden: false,
                        display: true
                    }
                ],
                _fc_drag_tag: 'yes-tabs-pane',
                hidden: false,
                display: true
            };
            tabRule.children.push(tabPaneRule);
            this.editableTabsValue = data.name;
        },
        removeContent() {
            const api = this.formCreateInject.api;
            const rule = this.formCreateInject.children.filter(a => a.props.name == this.editableTabsValue)[0];
            api.removeRule(rule);
            const index = this.formCreateInject.children.length - 1;
            this.editableTabsValue = this.formCreateInject.children[index].props.name;
        },
        updateNowPage() {
            this.formCreateInject.children.map(a => {
                if (a.props.name === this.editableTabsValue) {
                    a.children[0].__fc__.el.reLoad();
                }
            });
        },
        removeAllpage() {
            const api = this.formCreateInject.api;
            let delList = [];
            this.formCreateInject.children.map(a => {
                if (a.props.name != 'tab_home') delList.push(a);
            });
            delList.map(v => {
                api.removeRule(v);
            });
            this.editableTabsValue = this.formCreateInject.children[0].props.name;
        },
        async clickTab() {
            let data = {
                tab: this.editableTabsValue,
                otherData: this.otherData
            };

            const tabClickEvent = window.AsyncFunction(
                'vm',
                'api',
                'rule',
                'data',
                'dao',
                'utils',
                'paneName',
                this.tabClickEvent
            );
            await tabClickEvent(
                this,
                this.formCreateInject.api,
                this.formCreateInject.rule,
                data,
                dao,
                utils,
                this.editableTabsValue
            );
        },
        async removeTabConfirm(value) {
            const rule = this.formCreateInject.children.filter(a => a.props.name == value)[0];
            if (rule.props.close_confirm) {
                await utils.messageConfirm('  ', '确认关闭当前窗口离开吗？', async () => {
                    this.removeTab(value);
                });
            } else {
                this.removeTab(value);
            }
            return false;
        },
        removeTab(value) {
            if (this.editableTabsValue == value) {
                this.editableTabsValue = '0';
                this.$slots.default.forEach((solt, index) => {
                    if (solt.componentOptions.propsData.name === value) {
                        let nextTab = this.$slots.default[index + 1] || this.$slots.default[index - 1];
                        if (nextTab) {
                            this.editableTabsValue = nextTab.componentOptions.propsData.name;
                        }
                    }
                });
            }
            const api = this.formCreateInject.api;
            const rule = this.formCreateInject.children.filter(a => a.props.name == value)[0];
            api.removeRule(rule);
        },
        // 设置指定子标签编辑与否
        setDisabled(name, disabled) {
            const tabRule = this.formCreateInject.rule;
            let findRule = tabRule.children.find(rule => rule.name === name);
            findRule.props ? (findRule.props.disabled = disabled) : (findRule.props['disabled'] = disabled);
        },
        setCurrentTab(name) {
            if (name) {
                this.editableTabsValue = name;
            } else if (this.editableTabsValue === '0' && this.$slots.default) {
                this.editableTabsValue = this.$slots.default[0].componentOptions.propsData.name;
            }
        },
        setOtherData(data) {
            this.otherData = data;
        },
        // 获取当前的pane
        getCurrentPane() {
            return this.editableTabsValue;
        },
        judgeFieldNotNull(data, method) {
            let isNullArr = [];
            if (!data.label) {
                isNullArr.push('label');
            }
            if (!data.name) {
                isNullArr.push('name');
            }
            if (data.closable === '' || data.closable === null || data.closable === undefined) {
                isNullArr.push('closable');
            }
            if (method === 'addIframe') {
                if (!data.url) {
                    isNullArr.push('url');
                }
            }
            if (method === 'addComponent') {
                if (!data.app) {
                    isNullArr.push('app');
                }
                if (!data.code) {
                    isNullArr.push('code');
                }
            }
            return isNullArr;
        },
        judgeMaxLength(tabRule, title = '组件') {
            if (tabRule.children.length > this.maxLength || tabRule.children.length === this.maxLength) {
                return { flag: true, msg: `最多只能打开${this.maxLength}个子${title}` };
            } else {
                return { flag: false, msg: '' };
            }
        },
        getWindow(tabPaneId) {
            return document.getElementById(tabPaneId);
        }
    }
};
</script>
<style>
.el-tabs__content {
    height: calc(100% - 41px);
}

.el-tab-pane {
    height: 100%;
}
</style>
