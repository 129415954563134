<template>
    <div id="lineMain" :style="{ height: height, width: width }"></div>
</template>

<script>
const utils = require("@/api/core/utils");
// const dao = require('@/api/core/dao');
import * as echarts from "echarts";

export default {
    name: "yes-lineChart",
    props: {
        formCreateInject: {
            type: Object,
            required: true
        },
        dataSource: String,
        //x轴字段(1月，2月，3月....)
        xField: {
            type: String,
            default: ""
        },
        //y轴（150（1月）,250（2月）....）
        yField: {
            type: String,
            default: ""
        },
        nameField: {
            type: String,
            default: ""
        },
        title: {
            type: String,
            default: "lineChart"
        },
        showTooltip: {
            type: Boolean,
            default: true
        },
        marginTop: {
            type: String,
            default: "10%"
        },
        marginLeft: {
            type: String,
            default: "3%"
        },
        marginRight: {
            type: String,
            default: "3%"
        },
        marginBottom: {
            type: String,
            default: "3%"
        },
        canSaveImage: {
            type: Boolean,
            default: true
        },
        boundaryGap: {
            type: Boolean,
            default: false
        },
        height: {
            type: String,
            default: "100%"
        },
        width: {
            type: String,
            default: "100%"
        }
    },
    data() {
        return {
            isNew: false,
            viewData: [],
            xAxis_data: [],
            yAxis_data: [],
            legendList: [],
            numIndexArry: ["1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12"],
            textIndexArry: ["一", "二", "三", "四", "五", "六", "七", "八", "九", "十", "十一", "十二"],
            fDataSource: this.dataSource
        };
    },
    // created() {
    //   this.initEcharts();
    // },
    watch: {
        viewData() {
            this.parse();
        }
    },
    methods: {
        getTitle() {
            return this.title;
        },
        setTitle(val) {
            this.isNew = true;
            this.title = val;
        },
        getLegendList() {
            return this.legendList;
        },
        setLegendList(val) {
            this.isNew = true;
            this.legendList = val;
        },
        getXAxisData() {
            return this.xAxis_data;
        },
        setXAxisData(val) {
            this.isNew = true;
            this.xAxis_data = val;
        },
        getYAxisData() {
            return this.yAxis_data;
        },
        setYAxisData(val) {
            this.isNew = true;
            this.yAxis_data = val;
        },
        // 方法
        initEcharts() {
            // 基于准备好的dom，初始化echarts实例

            // 新建一个promise对象
            let newPromise = new Promise((resolve) => {
                resolve();
            });
            //然后异步执行echarts的初始化函数
            newPromise.then(() => {
                //	此dom为echarts图标展示dom
                var myChart = echarts.init(document.getElementById("lineMain"));
                // 绘制图表
                myChart.setOption({
                    title: {
                        text: this.title
                    },
                    legend: {
                        data: this.legendList
                    },
                    tooltip: {
                        show: this.showTooltip,
                        //是否显示提示框
                        trigger: "axis"
                    },
                    grid: {
                        top: this.marginTop,
                        left: this.marginLeft,
                        right: this.marginRight,
                        bottom: this.marginBottom,
                        //containLabel 为 true 的时候：
                        // grid.left grid.right grid.top grid.bottom grid.width grid.height 决定的是包括了坐标轴标签在内的所有内容所形成的矩形的位置。
                        // 这常用于『防止标签溢出』的场景，标签溢出指的是，标签长度动态变化时，可能会溢出容器或者覆盖其他组件。
                        containLabel: true
                    },
                    toolbox: {
                        show: this.canSaveImage,
                        feature: {
                            //保存图片
                            saveAsImage: {}
                        }
                    },
                    xAxis: {
                        //默认x轴类型
                        type: "category",
                        //x轴左右留白
                        boundaryGap: this.boundaryGap,
                        data: this.xAxis_data
                    },
                    yAxis: {
                        //默认y轴类型
                        type: "value"
                    },
                    series: this.yAxis_data
                });
            });
        },
        // 将组件的值返回
        getValue() {
            //   return this.href;
        },
        // 修改组件的值
        setValue() {
            //   this.href = val;
        },
        // 读取绑定数据源数据
        loadSourceData() {
            let da = utils.getSourceData(this.dataSource);
            this.viewData = da !== undefined ? da : this.data;
        },
        parse() {
            if (this.isNew) {
                return;
            }
            let list = this.viewData;
            list.forEach((item, index) => {
                //x轴字段
                if (index == 0) {
                    this.xAxis_data.push(item[this.xField]);
                    this.yAxis_data.push({
                        name: item[this.nameField],
                        type: "line",
                        data: [
                            {
                                num: item[this.yField],
                                name: item[this.xField]
                            }
                        ]
                    });
                    return;
                }
                let xcheck = false;
                for (let i in this.xAxis_data) {
                    if (JSON.stringify(this.xAxis_data[i]) == JSON.stringify(item[this.xField])) {
                        xcheck = true;
                        break;
                    }
                }
                if (!xcheck) {
                    this.xAxis_data.push(item[this.xField]);
                }
                //y值
                let ycheck = false;
                for (let i in this.yAxis_data) {
                    //如果y组已经存在当前名称的组 则在该组下继续按x轴为序增加数组
                    //如果所有的y组都不存在与当前同名 则标志位为
                    //   let count = this.yAxis_data.length;
                    if (this.yAxis_data[i].name == item[this.nameField]) {
                        this.yAxis_data[i].data.push({
                            num: item[this.yField],
                            name: item[this.xField]
                        });
                        ycheck = false;
                        break;
                    } else {
                        ycheck = true;
                    }
                }
                if (ycheck) {
                    this.yAxis_data.push({
                        name: item[this.nameField],
                        type: "line",
                        data: [
                            {
                                num: item[this.yField],
                                name: item[this.xField]
                            }
                        ]
                    });
                }
            });
            this.sorter();
        },
        sorter() {
            let data = [];
            for (let i in this.yAxis_data) {
                data[i] = [];
                this.xAxis_data.forEach(() => {
                    data[i].push(0);
                });
            }
            this.yAxis_data.forEach((item, i1) => {
                this.legendList.push(item.name);
                item.data.forEach((ya, i3) => {
                    this.xAxis_data.forEach((xa, i2) => {
                        if (xa == ya.name) {
                            data[i1][i2] = ya.num;
                            if (this.yAxis_data[i1].data.length - 1 == i3) {
                                this.yAxis_data[i1].data = data[i1];
                            }
                        }
                    });
                });
            });
            this.initEcharts();
        },
        // 修改绑定数据源数据
        updateSourceData() {}
    }
};
</script>
<style scoped></style>
